exports.components = {
  "component---src-layouts-about-js": () => import("./../../../src/layouts/about.js" /* webpackChunkName: "component---src-layouts-about-js" */),
  "component---src-layouts-contact-js": () => import("./../../../src/layouts/contact.js" /* webpackChunkName: "component---src-layouts-contact-js" */),
  "component---src-layouts-home-js": () => import("./../../../src/layouts/home.js" /* webpackChunkName: "component---src-layouts-home-js" */),
  "component---src-layouts-news-layout-js": () => import("./../../../src/layouts/newsLayout.js" /* webpackChunkName: "component---src-layouts-news-layout-js" */),
  "component---src-layouts-project-layout-js": () => import("./../../../src/layouts/projectLayout.js" /* webpackChunkName: "component---src-layouts-project-layout-js" */),
  "component---src-layouts-recruitment-layout-js": () => import("./../../../src/layouts/recruitmentLayout.js" /* webpackChunkName: "component---src-layouts-recruitment-layout-js" */),
  "component---src-layouts-what-we-do-js": () => import("./../../../src/layouts/whatWeDo.js" /* webpackChunkName: "component---src-layouts-what-we-do-js" */),
  "component---src-pages-dolacz-js": () => import("./../../../src/pages/dolacz.js" /* webpackChunkName: "component---src-pages-dolacz-js" */),
  "component---src-pages-newsyiopinie-js": () => import("./../../../src/pages/newsyiopinie.js" /* webpackChunkName: "component---src-pages-newsyiopinie-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

